@import '/src/scss/helpers/index.scss';

.select-comp {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .MuiFormLabel-root,
  .MuiSelect-nativeInput,
  .MuiSelect-select {
    color: $menu-text;
    font-weight: 400;
    font-size: 13px;

    &.Mui-focused,
    &.MuiFormLabel-filled {
      color: $menu-text;
      top: 2px;
      left: -2px;
    }
  }

  .MuiInputBase-root {
    border-radius: 6px;
    max-height: 40px;
    padding-right: 16px;

    &.Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        // border-color: red;
        border-width: 1px;
      }
    }
  }

  .MuiSelect-select {
    display: flex;
    align-items: center;
    padding: 14px 0 12px 14px !important;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: $select-border !important;
    transition: all .4s;

    span {
      font-size: 9.3px;
    }

    &:hover {
      color: $menu-text;
    }
  }

  .MuiSvgIcon-root {
    width: 18px;
    height: 18px;
  }

}

.select-comp-popup {

  &.MuiMenuItem-root {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px;
    background-color: $white;
    border-radius: 4px;
    transition: background-color .4s;
    font-size: 13px;
    font-weight: 400;
    color: $menu-text;

    &:hover {
      background-color: $select-menu-hover;
    }

    &.Mui-selected {
      background-color: $select-menu-active !important;
    }

    .MuiTypography-root {
      font-size: 13px;
      font-weight: 400;
      color: $menu-text;
      text-transform: capitalize;
    }

    .radio-wrapper {
      display: flex;
      flex-direction: row;
      gap: 2px;

      .MuiFormControlLabel-root {
        margin-right: 0;
        margin-left: 0;
      }

      .MuiRadio-root {
        padding: 4px;
      }
    }
  }
}

.select-placeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2;
  color: $input-placeholder;
}

.select-empty-state {
  padding: 8px !important;
  opacity: 1 !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  color: $gray-70 !important;
  text-transform: capitalize;
}

.toolkit-select {
  border-radius: 4px;
  background-color: $blue-10;

  .MuiSelect-select {
    display: flex;
    align-items: center;
    padding: 6px 6px 6px 12px !important;
    font-weight: 500;
    font-size: 13px;
    color: $black-60;
  }

  .select-arrow {
    margin-right: 12px;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: $gray-35 !important;
    border-width: 1px !important;
  }
}

.result-select {
  padding-right: 12px;
  border-radius: 16px !important;
  background-color: $blue-10;
  max-height: 32px;

  .MuiSelect-select {
    display: flex;
    align-items: center;
    padding: 9px 0 9px 12px !important;
    color: $black-60;
    font-weight: 500;
    font-size: 13px;
    line-height: 1;
    font-family: "Poppins", sans-serif;
  }

  .select-arrow {

    svg {
      min-width: 18px;
      min-height: 18px;
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: $result-select-border !important;
    border-width: 1px !important;
  }

  .select-placeholder {
    color: $black-60;
    font-weight: 500;
    font-size: 13px;
    line-height: 1;
  }
}

// global style
.MuiPaper-root {
  &.MuiMenu-paper {
    background-color: $white;
    border-radius: 6px;
    border: 1px solid $select-border;
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12) !important;

    .MuiList-root {
      padding: 12px;
    }
  }

}

.clr-btn {
  padding: 2px 2px 5px !important;
  line-height: 1 !important;
  margin-bottom: 5px !important;
}
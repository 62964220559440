@import '/src/scss/helpers/index.scss';


.navbar-container {
    display: flex;
    flex-direction: column;
    background-color: $navbar-bg;
    position: fixed;
    left: 0;
    height: 100%;
    top: 52px;
    z-index: 150;
    width: 70px;
    gap: 12px;
    padding: 16px 15px;
    transition: width .4s;

    &.expand {
        gap: 0px;
        padding: 0px;
        width: 268px;
    }
}

.title-container {
    padding: 16px 16px 16px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
}

.company-name {
    display: flex;
    align-items: center;

    width: 100%;
    height: 100%;
}

.link-wrapper {
    display: flex;
    flex-direction: column;

}

.link-container {
    display: flex;
    align-items: center;
    min-height: 56px;
    gap: 12px;
    cursor: pointer;
    padding: 11px 8px;
    transition: all .4s;

    &:hover {
        background-color: $navbar-menu-hover;
    }
}

.nav-icon {
    svg {
        width: 24px;
        height: 24px;
    }
}

.menu-title {
    color: $white;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.2;
    transition: all .4s;
}

.menu-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 24px;
    cursor: pointer;
    transition: all .4s;
    @include iconSize(40px, 40px, pointer);

    &:first-child {
        &:hover {
            background-color: transparent;
        }
    }

    &:hover,
    &-active {
        background-color: $navbar-bg-hover;
    }
}

.expand-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    @include iconSize(23px, 24px, pointer)
}
@import '/src/scss/helpers/index.scss';

.checkbox-select {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .select-container {
    .react-select__control {
      min-height: 40px;
      max-height: 40px;
      border-color: $select-border;
      border-radius: 6px;
      cursor: pointer;

      &--menu-is-open,
      &--is-focused {
        border-color: $select-border;
        box-shadow: none;
      }
    }

    .react-select__value-container {
      display: flex;
      padding: 0 4px 0 16px;
    }

    .search-icon {
      @include iconSize(24px, 24px, pointer);
    }

    .react-select__placeholder {
      position: absolute;
      left: 48px;
      font-weight: 400;
      font-size: 14px;
      line-height: 1.2;
      color: $input-placeholder;
      margin: 0;
    }

    .react-select__input-container {
      margin-left: 6px;
      font-weight: 400;
      font-size: 14px;
      line-height: 1.2;
      color: $black-80;
    }

    .selected-values {
      margin-left: 8px;
      font-weight: 400;
      font-size: 14px;
      line-height: 1.2;
      color: $black-80;

      max-width: 80%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .react-select__indicator-separator {
      display: none;
    }

    .dropdown-indicator {
      padding: 4px;
      padding-right: 16px;
    }


  }

  .react-select__menu {
    margin-top: 8px;
    border-radius: 4px;
    background-color: $white;
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12) !important;
    border: 1px solid $footer-border;

    .react-select__menu-list {
      padding: 12px;
    }

    .react-select__option {
      display: flex;
      align-items: center;
      gap: 8px;
      cursor: pointer;
      padding: 8px;
      transition: all .4s;

      &--is-focused {
        background-color: $select-menu-hover;
      }

      &--is-selected {
        background-color: $select-menu-active;
      }
    }

    .option-label {
      font-weight: 400;
      font-size: 13px;
      color: $black-80;
      line-height: 1.2;
      width: 100%;
      cursor: pointer;
    }

    .empty-state {
      display: flex;
      justify-content: start;
      font-weight: 400;
      line-height: 1.2;
      font-size: 13px;
      color: $gray-70;
    }
  }
}
@import '/src/scss/helpers/index.scss';

.card-container {
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 20px 20px 24px;
    background-color: $white;
    border-radius: 10px;
}

.card-header {
    display: flex;
    align-items: center;
    gap: 9px;
}

.card-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    background: linear-gradient(180deg, #B4C3CE 0%, #2788D2 100%);
    @include iconSize(48px, 48px);
}

.card-title {
    font-size: 18px;
    font-weight: 600;
    line-height: 1.2;
    color: $tab-text;
}
@import '/src/scss/helpers/index.scss';

.sidenav-menu {
  display: flex;
  flex-direction: column;
  gap: 4px;

  .menu-trigger {
    display: flex;
    align-items: center;
    padding: 4px 6px;
    min-height: 24px;
    max-height: 24px;
    gap: 8px;
    border-radius: 4px;
    margin-right: 24px;
    cursor: pointer;

    &.active {
      background-color: $navbar-acc-menu;
    }

    &::before {
      content: "";
      background-color: $white;
      position: absolute;
      width: 8px;
      height: .5px;
      left: 0;
    }
  }

  .menu-label {
    color: $white;
    font-weight: 500;
    font-size: 13px;
    line-height: 1.2;
  }

  .arrow-icon {
    svg {
      @include iconSize(13px, 13px, pointer);
    }
  }

  .szh-menu-container {
    ul {
      background-color: $white;
      padding: 8px;
      border-radius: 4px;
      min-width: 185px;
      border: 1px solid $menu-border;
      box-shadow: 0px 4px 20px 0px #00000021;
    }

    .szh-menu__item {
      padding: 8px 12px;
      border-radius: 4px;
      background-color: $white;
      font-weight: 500;
      font-size: 13px;
      line-height: 1.2;
      color: $menu-text;

      &:hover {
        background-color: $menu-hover;
      }
    }
  }
}
@import '/src/scss/helpers/index.scss';

.btn-comp {
  &.MuiButtonBase-root {
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 10px 12px;
    border-radius: 8px;
    box-shadow: none !important;
    text-transform: none;
    border: 1px solid transparent;
    min-width: fit-content;

    .btn-label {
      font-weight: 500;
      font-size: 14px;
      text-wrap: nowrap;
    }

    .MuiButton-icon {
      margin: 0;

      svg {
        @include iconSize(16px, 16px, pointer);
      }
    }

    &.primary-btn {
      background-color: $chip-active-bg;

      .btn-label {
        color: $white;
      }

    }

    &.secondary-btn {
      background-color: transparent;
      border-color: $chip-active-bg;

      .btn-label {
        color: $chip-active-bg;
      }

    }

    &.tertiary-btn {
      background-color: $gray-25;
      border-color: transparent;

      .btn-label {
        color: $blue-60;
      }

    }

    &.link-btn {
      background-color: transparent;
      gap: 4px;
      padding: 10px 16px;

      .btn-label {
        text-decoration: underline;
        color: $chip-active-bg;
        text-wrap: nowrap;
      }

      &:hover {
        .btn-label {
          text-decoration: none;
        }
      }
    }

    &.icon-only {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      padding: 8px;
      min-width: auto;
    }

    &.circle {
      border-radius: 50px !important;
    }

    &.bg-none {
      background-color: transparent;
      border-color: $chip-active-bg;

    }

    .counter {
      display: flex;
      justify-content: center;
      align-items: center;
      @include iconSize(19px, 19px, pointer);
      background-color: $orange-70;
      border-radius: 50%;
      color: $white;
      text-decoration: none;
      font-size: 13px;
      font-weight: 500;
      line-height: 0;
    }
  }
}
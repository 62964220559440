@import '/src/scss/helpers/index.scss';

.card-body {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.body-child {
    display: flex;
    flex-direction: column;
    gap: 12px;
    min-width: 420px;
}

.chip-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
}
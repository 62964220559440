@import './variables';


.cursor-pointer {
  cursor: pointer;
}


.line-container {
  width: 100%;
  display: block;
  height: 1px;
  background-color: $gray-24;
}


// search page 
.header-container {
  &.scrolled {
    box-shadow: 0px 10px 20px 0px #00000017;
  }
}

// result page
@import '/src/scss/helpers/index.scss';

.toaster-comp {
  top: 68px !important;
  right: 24px !important;


  &-alert {
    &.MuiAlert-root {
      padding: 12px 16px;
      max-width: 412px;
      min-width: 412px;
      background-color: $white;
      border: 1px solid $select-border;
      border-radius: 10px;
    }

    &.MuiAlert-colorSuccess {
      background-color: $toaster-success-bg;
      border-color: $toaster-success-border;
    }

    &.MuiAlert-colorError {
      background-color: $toaster-error-bg;
      border-color: $orange-70;
    }

    &.MuiAlert-colorInfo {
      background-color: $toaster-info-bg;
      border-color: $toaster-info-border;
    }

    .MuiAlert-icon {
      padding: 0;
    }

    .MuiAlert-message {
      padding: 0;
      color: $toaster-msg-text;
      font-weight: 400;
      font-size: 13px;
      font-family: "Poppins", sans-serif;
    }

    .MuiAlertTitle-root {
      color: $toaster-msg-text;
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 4px;
      font-family: "Poppins", sans-serif;
      text-transform: capitalize;
    }

    .MuiAlert-action {
      position: absolute;
      top: 12px;
      right: 16px;
      padding: 0;
      margin-left: 10px;
    }

    .MuiButtonBase-root {
      padding: 2px;

      svg {
        @include iconSize(16px, 16px, pointer);
      }
    }
  }
}
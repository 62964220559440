@import '/src/scss/helpers/index.scss';


.content-wrapper {
  display: flex;
  gap: 20px;
  align-items: center;
  flex-wrap: wrap;
}

.select-wrapper {
  min-width: 420px;
}

.body-child {
  display: flex;
  flex-direction: column;
  gap: 12px;
  min-width: 420px;
}

.chip-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}
@import '/src/scss/helpers/index.scss';

.header-container {
  display: flex;
  align-items: end;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 32px;
  position: sticky;
  padding: 32px 32px 8px;
  top: 52px;
  z-index: 99;
  background-color: $result-bg;
}


.header-title {
  font-weight: 600;
  font-size: 24px;
  color: $tab-text;
}

.chips-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  overflow-x: auto;
  padding-bottom: 8px;
}

.left-section {
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-width: 0;
  flex: 1 1 0;
}

.right-section {
  display: flex;
  align-items: center;
  gap: 8px;
  padding-bottom: 8px;
}

.children-container {
  padding: 12px 48px 50px;
}

.filter-modal-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.table-wrapper {
  width: 606px;
}

.button-wrapper {
  display: flex;
  align-items: center;
  gap: 12px;
  align-self: flex-end;
}

.table-button-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.filter-modal-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.table-wrapper {
  width: 606px;
}

.button-wrapper {
  display: flex;
  align-items: center;
  gap: 12px;
  align-self: flex-end;
}

.table-button-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}
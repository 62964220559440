@import '/src/scss/helpers/index.scss';


.modal-comp {
  .MuiPaper-root {
    display: flex;
    flex-direction: column;
    gap: 16px;
    background-color: $white;
    padding: 20px 20px 12px;
    border-radius: 12px;
    min-width: 380px;
    max-width: 100%;
  }

  .MuiDialogTitle-root {
    color: $menu-text;
    padding: 0;
    font-weight: 500;
    font-size: 16px;

  }

  .close-btn {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
  }

}
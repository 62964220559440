@import '/src/scss/helpers/index.scss';

.MuiTooltip-tooltip {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $menu-text !important;
  box-shadow: 0px 6px 14px 0px #0807071A;
  border-radius: 4px !important;
  max-height: 22px;
  font-family: "Poppins", sans-serif !important;
  color: $white !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  padding: 4px 6px !important;

  .MuiTooltip-arrow {
    width: 11px;
    height: 6px;
    margin-top: -5px !important;

    &:before {
      background-color: $menu-text;
    }
  }
}
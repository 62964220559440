// fontsize rem conversion
@mixin fz($sizeValue: 1.6) {
	font-size: ($sizeValue * 10) + px;
	font-size: $sizeValue + rem;
}

// pseudo elements
@mixin pseudo($content: '', $pos: absolute) {
	content: $content;
	position: $pos;
}

// position center
@mixin posCenter($position) {
	position: absolute;

	@if $position =='vertical' {
		top: 50%;
		transform: translateY(-50%);
	}

	@else if $position =='horizontal' {
		left: 50%;
		transform: translate(-50%);
	}

	@else if $position =='both' {
		top: 50%;
		left: 50%;
	}
}

@mixin iconSize($width, $height, $cursor: default) {
	min-width: $width;
	max-width: $width;
	min-height: $height;
	max-height: $height;
	cursor: $cursor;

}

@mixin custom-scroll() {
	& ::-webkit-scrollbar {
		width: 6px;
		height: 6px;
		scrollbar-color: light;
		scrollbar-width: bold;
		scrollbar-color: $gray-55 transparent;
	}

	& ::-webkit-scrollbar-button,
	& ::-webkit-scrollbar-corner {
		display: none;
	}

	& ::-webkit-scrollbar-thumb {
		background: $gray-55;
		border-radius: 100px;
		width: 4px;
	}

	&::-webkit-scrollbar-track {
		background: $white;
	}

	&:hover {
		&::-webkit-scrollbar-thumb {
			background: $gray-55;
		}
	}
}
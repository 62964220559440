@import '/src/scss/helpers/index.scss';

.page-container {
  display: flex;
  flex-direction: column;
  background-color: $result-bg;
  min-height: calc(100vh - 52px);
}

.header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 42px 12px 32px;
  background-color: $result-bg;
  position: sticky;
  top: 52px;
  z-index: 99;
  flex-wrap: wrap;
  gap: 12px;
}

.option-wrapper {
  display: flex;
  align-items: center;
  gap: 12px;
}

.select-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
}

.input-container {
  display: flex;
  align-items: center;
  gap: 12px;
}

.left-section {
  display: flex;
  align-items: center;
  gap: 16px;
}

.section-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 1.2;
  color: $tab-text;
}

.right-section {
  display: flex;
  align-items: center;
  gap: 16px;
}

.filter-modal-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.table-wrapper {
  width: 606px;
}

.button-wrapper {
  display: flex;
  align-items: center;
  gap: 12px;
  align-self: flex-end;
}

.table-button-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.filter-modal-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}


.button-wrapper {
  display: flex;
  align-items: center;
  gap: 12px;
  align-self: flex-end;
}

.table-button-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.table-outside-wrapper {
  width: 100%;
}


.share-modal-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.social-wrapper {
  display: flex;
  align-items: center;
  gap: 20px;
}

.social-container {
  display: flex;
  flex-direction: column;
  gap: 9px;
  align-items: center;
  cursor: pointer;
}

.social-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  width: 70px;
  padding: 16px;
  border-radius: 50%;
  background-color: $blue-15;

  &.mail {
    background-color: $red-30;
  }

  &.whatsapp {
    background-color: $green-30;
  }
}

.social-name {
  font-weight: 500;
  font-size: 14px;
  color: $share-modal-text;
  text-transform: capitalize;
}

.button-wrapper {
  display: flex;
  gap: 12px;
  align-items: center;
  align-self: flex-end;
}
@import '/src/scss/helpers/index.scss';

.content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.diamond-section {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.title-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.select-all-btn {
  padding: 0 !important;
}

.card-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px;
}

.card-container {
  position: relative;
  display: flex;
  margin: 8px 0;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 8px 8px 30px;
  background-color: $diamond-bg;
  border-radius: 88px;
  cursor: pointer;
  transition: all .4s;
  min-width: 97px;
  max-width: 97px;

  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    border-radius: 40px;
    background: $white;

    svg {
      width: 56px;
      height: 56px;
    }
  }

  &:hover,
  &.active {
    background-color: $chip-active-bg;

    .card-text {
      color: $white;
    }
  }

  &:hover {
    transform: translateY(-8px);
  }

  &.active {

    .image-container {
      background: $diamond-bg-hover;

      svg {
        g {
          path {
            fill: $tab-text;
            stroke: $tab-text
          }
        }
      }
    }
  }
}


.card-text {
  font-weight: 500;
  font-size: 13px;
  line-height: 1;
  color: $chip-text;
  text-transform: capitalize;
}

.tick-icon {
  position: absolute;
  top: 12px;
  right: 10px;
}

.carat-section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.input-section {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.clarity-section {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.chip-wrapper {
  display: flex;
  align-items: center;
  gap: 6px;
  flex-wrap: wrap;
}

.input-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.number-wrapper {
  display: flex;
  gap: 16px;
}

.select-container {
  max-width: 148px;
}

.input-add-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  background-color: $navbar-bg;
  border-radius: 50px;
  cursor: pointer;
}

.add-btn {
  min-width: 24px !important;
  margin-top: 8px !important;
}
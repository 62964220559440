@import '../helpers/variables';

/* Use a more-intuitive box-sizing model. */
*,
*::before,
*::after {
    box-sizing: border-box;
}

/* Remove default margin and padding and reset fonts */
* {
    margin: 0;
    padding: 0;
    font: inherit;

    &:focus {
        outline: none !important;
        box-shadow: none !important;
    }
}

/*
  Typographic tweaks!
  Add accessible line-height
  Improve text rendering
*/
html {
    font-size: $reset-rem-size;
    @include custom-scroll;
}

* {
    font-family: $base-font-family;
    color: $black;
}

body {
    min-height: 100vh;
    text-rendering: optimizeSpeed;
    line-height: 1.3;
    -webkit-font-smoothing: antialiased;
}

/* Improve media defaults */
img,
picture,
video,
canvas,
svg {
    display: block;
    max-width: 100%;
}

/* Avoid text overflows */
p,
h1,
h2,
h3,
h4,
h5,
h6 {
    overflow-wrap: break-word;
}

/* Create a root stacking context */
#root,
#__next {
    isolation: isolate;
}

input {
    &[type='number'] {
        appearance: textfield;

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
        -webkit-text-fill-color: #000;
        box-shadow: 0 0 0px 1000px #fff inset;
        transition: background-color 5000s ease-in-out 0s;
    }

    &[type='search']::-webkit-search-decoration,
    &[type='search']::-webkit-search-cancel-button,
    &[type='search']::-webkit-search-results-button,
    &[type='search']::-webkit-search-results-decoration {
        -webkit-appearance: none;
    }
}

ul,
ol {
    &[role='list'] {
        list-style: none;
    }
}

blockquote,
q {
    quotes: none;

    &:before,
    &:after {
        content: none;
    }
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

pre {
    font-family: $base-font-family;
    tab-size: 2;
}
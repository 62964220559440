@import '/src/scss/helpers/index.scss';

.table-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.header-wrapper {
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap: 20px;
  padding: 16px 40px 8px 32px;
  position: sticky;
  top: calc(52px + 72px);
  z-index: 99;
  background-color: $result-bg;

  @include mq(phone) {
    flex-wrap: wrap;
  }
}

.button-wrapper {
  display: flex;
  align-items: center;
  gap: 14px;
}

.header-title {
  font-weight: 400;
  font-size: 16px;
  color: $menu-text;
  margin-top: 5px;
}


.data-wrapper {
  display: flex;
  gap: 20px 40px;
  align-items: center;
  flex-wrap: wrap;

  @include mq(desktop-md) {
    gap: 20px;
  }
}

.data-container {
  position: relative;
  display: flex;
  align-items: center;
  gap: 16px;
  padding-right: 40px;

  @include mq(desktop) {
    min-width: 160px;
    padding-right: 20px;
  }

  @include mq(desktop) {
    gap: 12px;
    align-items: start;
  }

  &::after {
    content: "";
    width: 1px;
    position: absolute;
    top: 7px;
    right: 0;
    background-color: $divider-bg;
    height: 26px;
  }

  &:last-child {
    padding-right: 0;

    &::after {
      display: none;
    }
  }
}

.data-icon {
  display: flex;
  justify-content: center;
  align-items: center;

  svg,
  img {
    height: 40px;
    width: 40px;

    @include mq(desktop-md) {
      height: 36px;
      width: 36px;
    }
  }
}

.value-container {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.value {
  font-size: 24px;
  font-weight: 500;
  color: $tab-text;

  @include mq(desktop-md) {
    font-size: 18px;
    font-weight: 600;
  }
}

.text {
  font-weight: 400;
  font-size: 13px;
  color: $tab-text;
}

.blue {
  color: #1259E6;
}

.red {
  color: #DE1E58;
}

.lightGreen {
  color: #189E45;
}

.yellow {
  color: #CB9E00;
}

.darkGreen {
  color: #007863;
}

.lightBlue {
  color: #0E7EA1;
}

.horizontal-divider {
  background-color: $divider-bg;
  width: 1px;
  height: 26px;
}

.toolkit-wrapper {
  width: 100%;
  background-color: $white;
  height: 100%;

  .toolkit-header {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 7px 12px;
    border-bottom: 1px solid $gray-39;
  }

  .child-wrapper {
    max-height: calc(100vh - 347px);
    overflow-y: auto;
  }

  .child-container {
    padding: 8px 16px;
    display: flex;
    align-items: center;
    gap: 10px;
    min-height: 36px;
    max-height: 36px;
    cursor: pointer;
    background-color: $white;

  }

  .action-block {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .child-name {
    font-weight: 400;
    font-size: 13px;
    color: $black-50;
    cursor: pointer;
  }

}

.chips-section {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
  margin-top: 8px;
  padding: 0 40px 0 32px;

  .chips-title {
    font-weight: 400;
    font-size: 13px;
    color: $black-50;
  }

  .clear-btn {
    padding: 0 !important;
  }
}



// popup
.process-content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-width: 606px;

  .modal-footer {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 12px;
  }
}


.table-drop-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  gap: 8px;

  .data-value {
    font-weight: 400;
    font-size: 13px;
    color: $menu-text;
  }

  .avatar-container {
    display: flex;
    align-items: center;
    gap: 6px;
  }
}

.chip-key {
  font-weight: 400;
  font-size: 13px;
  color: $gray-70;
}

.chip-value {
  font-weight: 500;
  font-size: 13px;
  color: $gray-70;
}





// filter 
.filter-data-wrapper {
  display: flex;
  flex-direction: column;

  .data-container {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 8px 16px;
    cursor: pointer;
  }

  .filter-icon {
    @include iconSize(20px, 20px);
  }

  .filter-text {
    font-weight: 400;
    font-size: 13px;
    line-height: 1;
    color: $black-50;
  }

  .item-border {
    border-bottom: 1px solid $gray-39;
  }
}
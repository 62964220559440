// typography
$base-font-family: "Poppins", sans-serif;
$reset-rem-size: 62.5%;
$font-size-base: 1.6rem;

// color
$black: #000;
$white: #fff;

// border radius
$border-radius: 6px;


// 22-07 var
$navbar-bg: #31708E;
$navbar-bg-hover: #FFFFFF26;
$navbar-menu-hover: #3D85A8;
$navbar-accordion-hover: #1E5874;
$navbar-acc-menu: #2D6E8D;

$menu-border: #C5C9DD;
$menu-hover: #F1F7FF;
$menu-text: #1A1A1A;

$secondary-btn-bg: #F2F2F4;

$tab-text: #19191B;
$tab-border: #EDEDF1;

$chip-active-bg: #5085A5;
$chip-border: #E3E4EB;
$chip-text: #5F5F64;
$specification-bg: #E3E4EB4D;

$btn-counter-text: #FF0000;
$select-border: #BCBEC8;
$footer-border: #E5E6EC;
$input-placeholder: #777779;

$diamond-bg: #8FC1E34D;
$diamond-bg-hover: linear-gradient(0deg, rgba(143, 193, 227, 0.3), rgba(143, 193, 227, 0.3)), linear-gradient(0deg, #FFFFFF, #FFFFFF);

$share-modal-text: #525967;
$select-menu-hover: #E5EBEF;

$select-menu-active: #DDECF7;

$divider-bg: #CACACA;

$table-border: #D7E2E5;
$table-header-border: #919499;

$result-bg: #EFEFF3;
$result-select-border: #B7CFDD;

$result-chip-border: #C8CACB;
$result-chip-border-active: #31708E;
$result-chip-bg-active: #DDECF7;

$toaster-success-bg: #EDF7ED;
$toaster-success-border: #4CAF50;
$toaster-msg-text: #040919;
$toaster-error-bg: #FDEDED;
$toaster-info-border: #01A9F4;
$toaster-info-bg: #E5F6FD;





// old colors
$blue-30: #95C7F6;
$blue-20: #D9EBF9;
$black-50: #1E1F21;
$red-30: #FFECEA;
$green-30: #E9F9F3;




// new colors 
// gray
$gray-75: #525967;
$gray-70: #535862;
$gray-55: #A7AFBC;
$gray-56: #C5C9DD;
$gray-50: #CCCFDC;
$gray-45: #CCCFDC;
$gray-44: #D8E1E9;
$gray-43: #E3E6F1;
$gray-42: #EEF1FF;
$gray-41: #E7EBF1;
$gray-40: #E5E6EC;
$gray-39: #DDE2EB;
$gray-38: #F6F6F6;
$gray-37: #EFEBEB;
$gray-35: #D6E7F6;
$gray-34: #EAEDF2;
$gray-25: #ECF1F6;
$gray-24: #E2E4F3;
$gray-20: #F9FBFD;
$gray-15: #ECEFFF;



// blue
$blue-75: #1D285A;
$blue-70: #003667;
$blue-60: #0D2D7D;
$blue-50: #28377E;
$blue-45: #404C8C;
$blue-40: #5D70D6;
$blue-15: #F1F9FE;
$blue-10: #F1F7FF;
$blue-05: #F2F4FF;


// orange
$orange-70: #E4411D;


// black
$black-80: #1A1A1A;
$black-60: #202329;
$black-50: #181D1F;


$download-btn-bg: #EBF6FD;
$download-btn-content: #2965BF;
$share-btn-bg: #F6ECD2;
$share-btn-content: #A87B09;
$filter-btn-bg: #FCF0FB;
$filter-btn-content: #BF40BA;
$save-btn-bg: #D9F6E7;
$save-btn-content: #2B9F60;
$table-cell-dot-red-bg: #E98F8F;
$table-cell-dot-blue-bg: #8484FF;
$table-cell-dot-brown-bg: #999696;
$table-cell-active: #ECEFF6;
@import '/src/scss/helpers/index.scss';

.menu-comp {
  .MuiPaper-root {
    background-color: $white;
    border-radius: 8px;
    border: 1px solid $gray-56;
    box-shadow: 0px 4px 20px 0px #00000021;

  }

  .MuiList-root {
    padding: 12px 0 !important;
  }

  .MuiButtonBase-root {
    padding: 4px 40px 4px 16px;
    font-weight: 400;
    font-size: 13px;
    color: $menu-text;

    &:hover {
      background-color: $gray-43;
    }
  }
}
.card-wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
}

.container {
    padding: 8px 32px 32px;
    background-color: $result-bg;
}
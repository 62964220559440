@import '/src/scss/helpers/index.scss';

.radio-comp {
  .MuiFormControlLabel-root {
    margin-left: 0;
    margin-right: 20px;
    gap: 6px;
  }

  .MuiButtonBase-root {
    padding: 0;
  }

  .MuiFormControlLabel-label {
    font-weight: 500;
    font-size: 14px;
    line-height: 1.2;
    color: $tab-text;
  }
}
@import '/src/scss/helpers/index.scss';


.chip-comp {
    &.MuiChip-root {
        display: flex;
        align-items: center;
        gap: 8px;
        height: auto;
        background-color: transparent;
        border: 1px solid $chip-border;
        padding: 8px 12px;
        transition: all .4s;
        cursor: pointer;
        border-radius: 4px;

        .MuiChip-label {
            display: flex;
            align-items: center;
            gap: 4px;
            padding: 0;
            font-weight: 500;
            color: $chip-text;
            // font-size: 13px;
            transition: all .4s;
        }

        &:hover,
        &.active {
            background-color: $chip-active-bg;
            border-color: $chip-active-bg;

            .MuiChip-label {
                color: $white;

                svg {
                    path {
                        stroke: $white;
                    }

                    rect {
                        stroke: $white
                    }
                }
            }
        }

        .dlt-icon {
            margin: 0;

            &:hover {
                svg {
                    path {
                        stroke: $white;
                    }
                }
            }
        }

        &.closable-chip {
            cursor: default;

            &.active {
                .dlt-icon {
                    svg {
                        path {
                            stroke: $white;
                        }
                    }
                }
            }
        }

        // small card
        &.chip-comp-small {
            height: 25px;
            padding: 6px 10px;
        }

        // bg-white chip
        &.bg-white {
            background-color: $white;
            border-color: $chip-border;

            .MuiChip-label {
                color: $tab-text;
            }

            &:hover,
            &.active {
                background-color: $chip-active-bg;
                border-color: $chip-active-bg;

                .MuiChip-label {
                    color: $white;
                }
            }
        }

        &.uppercase {
            .MuiChip-label {
                text-transform: uppercase;
            }
        }

        &.light-bg {
            background-color: transparent;
            border-color: $navbar-bg;

            .MuiChip-label {
                color: $tab-text;
            }

            &:hover,
            &.active {
                background-color: $chip-active-bg;
                border-color: transparent;

                .MuiChip-label {
                    color: $white;
                }
            }
        }

        &.result-chip {
            background-color: transparent;
            border-color: $result-chip-border;

            &:hover,
            &.active {
                background-color: $result-chip-bg-active;
                border-color: $result-chip-border-active;

                .MuiChip-label {
                    color: $navbar-bg;

                    span {
                        color: $navbar-bg;
                    }
                }

                .dlt-icon {
                    svg {
                        path {
                            stroke: $navbar-bg;
                        }
                    }
                }
            }
        }

        &.rounded {
            border-radius: 17.5px;
        }
    }

}
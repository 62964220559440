@import '/src/scss/helpers/index.scss';


.navbar-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $white;
    position: sticky;
    top: 0;
    height: 52px;
    padding-right: 32px;
    z-index: 99;
}

.left-wrapper {
    display: flex;
    align-items: center;
}


.details-wrapper {
    display: flex;
    align-items: center;
    gap: 20px;
}

.nav-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    @include iconSize(24px, 24px, pointer);
}

.profile-pic {
    display: flex;
    justify-content: center;
    align-items: center;
    @include iconSize(32px, 32px, pointer);
}

.logo-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 70px;
    height: 52px;
    background-color: $navbar-bg;
    padding: 2px 11px;
}
// font family
.font-family-primary {
  font-family: $base-font-family;
}

.input-label {
  font-weight: 600;
  font-size: 14px;
  line-height: 1.2;
  color: $tab-text;
}

.font-medium {
  font-weight: 500;
}
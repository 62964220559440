@import '/src/scss/helpers/index.scss';

.tab-comp {
  .header-wrapper {
    display: flex;
    gap: 12px;
    width: 100%;
    align-items: center;
  }

  .prefix-text {
    font-weight: 600;
    font-size: 14px;
    line-height: 1.2;
    color: $tab-text;
  }

  .MuiTabs-root {
    min-height: 32px;

  }

  .MuiTabs-indicator {
    display: none;
  }

  .MuiTabs-flexContainer {
    background-color: $chip-border;
    border-radius: 8px;
    padding: 2px;
  }

  .MuiTabs-scroller {
    .MuiButtonBase-root {
      padding: 0;
      min-height: 28px;
      border-radius: 6px;
      font-weight: 500;
      color: $tab-text;
      font-size: 12px;
      line-height: 1.2;
      text-transform: capitalize;
      font-family: $base-font-family;


      &.Mui-selected {
        background-color: $white;
        box-shadow: 1px 1px 4px 0px #19191B26;

      }
    }
  }

  .MuiBox-root {
    padding: 12px 0 0;
  }
}

.draggable-tab {
  .MuiTab-root {
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
    padding: 16px 12px;
    font-weight: 500;
    font-size: 13px;
    color: $tab-text;
    line-height: 1;
    background-color: $white;
    text-transform: capitalize;
    opacity: 1;
    min-height: auto;
    border-right: 1px solid $tab-border;
    font-family: $base-font-family;

    .close-btn {
      min-width: 16px !important;
      width: 16px !important;
      height: 16px !important;
    }

    &.active-tab {
      background-color: $result-bg;
    }

    .MuiTab-iconWrapper {
      margin: 0;
    }
  }

  .MuiTabs-indicator {
    display: none;
  }

}

.result-tab-comp-wrapper {
  background-color: $white;

  .result-tab-comp {
    min-height: 37px;
  }

  .MuiButtonBase-root.MuiTab-root {
    padding: 8px 15px;
    min-width: 85px;
    max-width: 85px;
    min-height: 37px;
  }

  .MuiTabs-indicator {
    background-color: $navbar-bg;
  }

  .filter-header {
    padding: 7px 12px;
    border-bottom: 1px solid $gray-39;
  }

  .MuiFormControl-root {
    height: 26px;

    .MuiInputBase-input {
      padding-left: 12px;
    }
  }
}
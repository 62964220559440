@import '/src/scss/helpers/index.scss';


.financial-content {
  display: flex;
  flex-wrap: wrap;
  gap: 40px 20px;
}

.content-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  min-width: 420px;
  max-width: 420px;
}

.child-title {
  font-weight: 500;
  font-size: 13px;
  color: $menu-text;

}

.input-wrapper {
  display: flex;
  gap: 16px;
}
@import '/src/scss/helpers/index.scss';


.footer-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 10px 32px;
  background-color: $white;
  position: sticky;
  bottom: 0;
  border-top: 1px solid $footer-border;
  box-shadow: 0px 0px 20px 0px #00000017;
  z-index: 99;
  max-height: 60px;
}

.selected-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.2;
  color: $tab-text;
}

.btn-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.share-modal-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.social-wrapper {
  display: flex;
  align-items: center;
  gap: 20px;
}

.social-container {
  display: flex;
  flex-direction: column;
  gap: 9px;
  align-items: center;
  cursor: pointer;
}

.social-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  width: 70px;
  padding: 16px;
  border-radius: 50%;
  background-color: $blue-15;

  &.mail {
    background-color: $red-30;
  }

  &.whatsapp {
    background-color: $green-30;
  }
}

.social-name {
  font-weight: 500;
  font-size: 14px;
  color: $share-modal-text;
  text-transform: capitalize;
}

.button-wrapper {
  display: flex;
  gap: 12px;
  align-items: center;
  align-self: flex-end;
}

.input-modal-content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-width: 606px;
}
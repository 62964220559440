@import '/src/scss/helpers/index.scss';


.content-container {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.card-children-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
    background-color: $specification-bg;
    border-radius: 8px;
}

.card-header {
    display: flex;
    align-items: center;
    gap: 20px;
}

.card-body {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    .inner-child {
        min-width: 420px;
    }
}

.body-child {
    display: flex;
    flex-direction: column;
    gap: 12px;
    min-width: 566px;
}

.child-title {
    font-size: 13px;
    font-weight: 500;
    color: $menu-text;
    text-transform: capitalize;
}

.chip-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
}
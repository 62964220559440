@import '/src/scss/helpers/index.scss';

.input-comp {

    .MuiInputLabel-root {
        color: $input-placeholder;
        font-weight: 400;
        font-size: 13px;
        line-height: 1.1;

        &.Mui-focused,
        &.MuiFormLabel-filled {
            top: 4px;
            color: $black-50;
        }
    }

    .MuiInputBase-root {
        height: 40px;
        border-radius: 6px;
        font-weight: 400;
        font-size: 13px;
        line-height: 1.1;
        color: $black-60;

        &:hover {
            .MuiOutlinedInput-notchedOutline {
                border-color: $select-border ;
            }
        }

        &.Mui-focused {
            .MuiOutlinedInput-notchedOutline {
                border-color: $select-border ;
                border-width: 1px;
            }
        }

        &.Mui-error {
            .MuiOutlinedInput-notchedOutline {
                border-color: $orange-70;
            }
        }
    }

    .MuiInputBase-input {
        padding: 0;
        padding-left: 16px;
        max-height: 40px;
        height: 100%;

        &::placeholder {
            color: $input-placeholder;
            opacity: 1;
            font-size: 14px;
            font-weight: 400;
            line-height: 1.2;
        }
    }

    .MuiOutlinedInput-notchedOutline {
        border-color: $select-border;
        transition: border-color .4s;
    }

    &.textArea-comp {
        height: 100%;
        min-height: 105px;

        .MuiInputBase-root {
            align-items: start;
            height: 100%;
        }

        .MuiInputBase-input {
            padding: 0;
        }
    }

    .Mui-disabled {
        background-color: $chip-border;
        border-radius: 6px;

        .MuiOutlinedInput-notchedOutline {
            border-color: $select-border !important;
        }
    }

    .MuiFormHelperText-root {
        &.Mui-error {
            color: $orange-70;
            margin: 4px 4px 4px 0;
            font-family: $base-font-family;
            font-size: 12px;
            font-weight: 400;

        }
    }
}

.result-input {
    padding-right: 12px !important;
    min-height: 32px;
    max-height: 32px;
    max-width: 60px;
    background-color: $white;

    .MuiInputBase-input {
        padding: 7px 0 7px 12px;
        color: $menu-text;
        font-weight: 400;
        font-size: 13px;

        &::placeholder {
            color: $menu-text;
            opacity: 1;
        }
    }

    .MuiOutlinedInput-notchedOutline {
        border-color: $menu-border !important;
        border-width: 1px !important;
    }

    .MuiTypography-root {
        color: $menu-text;
        font-weight: 400;
        font-size: 13px;
    }
}
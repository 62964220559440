@import '/src/scss/helpers/index.scss';

.child-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  align-items: center;
  gap: 16px;

  @include mq(desktop-md) {
    grid-template-columns: 1fr 1fr 2.5fr;
  }

  @include mq(desktop) {
    grid-template-columns: 1fr 1fr;
  }
}

.supplier-select {
  min-width: 330px;
  max-width: 100%;
}

.select-wrapper {
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr;
  align-items: center;
  gap: 16px;

  @include mq(desktop) {
    grid-column: 1/3;
  }
}
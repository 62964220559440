@import '/src/scss/helpers/index.scss';

.modal-table-comp {
  .ag-root-wrapper {
    border-radius: 8px;
    border-color: $gray-34;
  }

  .ag-header {
    background-color: $gray-38;
    border-color: $gray-34;
  }

  .ag-header-cell {
    padding: 12px 16px;
    justify-content: space-between;
    border-right: 1px solid $gray-34;

    &:last-child {
      border-right: none;
    }
  }

  .ag-header-cell-resize {
    display: none;
  }

  .ag-cell-label-container {
    padding: 0;
  }

  .ag-header-cell-label {
    justify-content: space-between;
  }

  .ag-header-cell-text {
    color: $gray-75;
    font-weight: 400;
    font-size: 13px;
  }

  .ag-body {
    .ag-row {
      background-color: $white;
      border-color: $gray-34;

      &::before {
        display: none;
      }

      .ag-cell {
        display: flex;
        align-items: center;
        gap: 8px;
        border: none !important;
        border-right: 1px solid $gray-34 !important;
        padding: 9px 16px;
        line-height: 1;
        color: $menu-text;
        font-weight: 400;
        font-size: 13px;

        &:last-child {
          border-right: none;
        }

        .apply-btn {
          padding: 8px;
        }

        .icon-container {
          display: flex;
          justify-content: center;
          align-items: center;
          @include iconSize(32px, 32px, pointer);
          cursor: pointer;
          background-color: $gray-38;
          border-radius: 5px;
          border: 1px solid $gray-40;
        }

        .pre-data {
          line-height: 16.9px;
        }
      }
    }
  }

  .ag-header-cell-menu-button {
    display: none;
  }
}

.table-content-wrapper {
  height: calc(100vh - 260px);
  width: 100%;
  padding: 0 40px 20px 32px;
}

.result-table {

  --ag-range-selection-background-color: $white;
  --ag-range-selection-border-color: transparent;

  .ag-root-wrapper {
    border-radius: 8px;
    border-color: $tab-border;
  }

  .ag-header {
    background-color: $gray-38;
    border-color: $table-header-border;
    min-height: 44px !important;
  }

  .ag-header-row {
    height: 44px !important;
  }

  .ag-column-first {
    .ag-header-cell-comp-wrapper {
      display: none;
    }
  }

  .ag-checkbox {
    margin-right: 0;
  }

  .ag-header-cell {
    padding: 13px 12px;
    justify-content: space-between;
    height: 44px !important;

    &.ag-header-active {
      .ag-header-icon {
        opacity: 1;
      }
    }

    &:after {
      position: absolute;
      right: 0;
      content: "";
      width: 1px;
      height: 13px;
      background-color: $table-header-border;
    }

    &:last-child {
      &::after {
        opacity: 0;
      }
    }
  }

  .ag-cell-label-container {
    padding: 0;
    flex-direction: row;
  }

  .ag-header-cell-label {
    justify-content: space-between;
    order: -1;
  }

  .ag-header-cell-text {
    color: $tab-text;
    font-weight: 500;
    font-size: 13px;
  }

  .ag-pinned-left-header {

    .ag-column-first {
      &:after {
        opacity: 0;
      }
    }

  }

  .ag-header-cell-menu-button {
    margin-right: 8px;
  }

  .ag-body {
    .ag-row {
      background-color: $white;
      border-color: $table-border;

      &.ag-row-selected {
        background-color: $white;
      }


      &:hover {
        background-color: $menu-hover;
      }

      &::before {
        display: none;
      }

      .ag-cell {
        display: flex;
        align-items: center;
        gap: 10px;
        border: none;
        padding: 13px 12px;
        line-height: 1;
        color: $tab-text;
        font-weight: 400;
        font-size: 13px;

        &.ag-cell-last-left-pinned {
          border-right: 1px solid $gray-41;
        }

        svg {
          cursor: pointer;
        }

        .dot {
          height: 12px;
          width: 12px;
          border-radius: 50%;

          &-red {
            background-color: $table-cell-dot-red-bg;
          }

          &-blue {
            background-color: $table-cell-dot-blue-bg;
          }

          &-brown {
            background-color: $table-cell-dot-brown-bg;
          }
        }
      }
    }
  }

  .ag-checkbox-input-wrapper {
    box-shadow: none !important;
    background-color: transparent;

    &::after {
      color: $navbar-bg !important;
      font-size: 14px;
      background-color: transparent;
    }
  }

  .ag-column-menu-visible {
    .ag-header-icon {
      opacity: 1;
    }
  }

  .ag-header-icon {

    opacity: 0;

    .ag-icon {
      &::before {
        display: none
      }
    }
  }

  .ag-column-first {
    .ag-header-cell-comp-wrapper {
      display: none !important;
    }
  }

  .ag-side-buttons {
    width: 30px !important;

    .ag-side-button-button {
      padding: 18px 7px;
      min-height: auto;
    }

    .ag-side-button-icon-wrapper {
      margin-bottom: 6px;
    }

    .ag-side-button-label {
      font-weight: 400;
      font-size: 13px;
      color: $tab-text;
    }

    .ag-selected {
      .ag-side-button-button {
        background-color: $menu-hover;
        border-left: 2px solid $navbar-bg !important;

        .ag-side-button-label {
          color: $navbar-bg;
        }

        .ag-side-button-icon-wrapper {
          svg {
            path {
              fill: $navbar-bg;
              stroke: $navbar-bg;
            }
          }
        }
      }
    }
  }

  .ag-react-container {
    width: 100%;
  }

  .toolkit-input {
    .MuiInputBase-root {
      border-radius: 6px;
      height: 26px;
    }

    .MuiInputBase-input {
      padding: 4px 12px;
      height: 26px;
      color: $menu-text;
      font-weight: 400;
      font-size: 13px;
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: $menu-border !important;
      border-width: 1px !important;

    }
  }

  .ag-filter-menu {
    border: none;
    border-radius: 6px;
  }

  .ag-header-cell-menu-button {
    display: none;
  }

}



// scrollbar style
.table-content-wrapper {
  @include custom-scroll;
}


.ag-theme-quartz,
.ag-theme-quartz-dark {
  --ag-icon-image-menu-alt: url('../../../../public/Images/svg/kebabicon.svg');
}
@import '/src/scss/helpers/index.scss';

.navbar-accordion {
  &.MuiAccordion-root {
    box-shadow: none;

    &::before {
      display: none;
    }

    &.Mui-expanded {
      margin-top: 0 !important;

      .MuiAccordionSummary-root {
        background-color: $navbar-menu-hover;

        .MuiAccordionSummary-content {
          color: $white;
        }
      }
    }

    .MuiAccordionSummary-root {
      background-color: $navbar-bg;
      transition: all .4s;
      min-height: 56px !important;
      padding: 11px 7px;

      &:hover {
        background-color: $navbar-menu-hover;

        .MuiAccordionSummary-content {
          color: $white;
        }
      }
    }



    .MuiAccordionSummary-content {
      margin: 0 !important;
      gap: 12px;
      align-items: center;
      color: $white;
      font-size: 14px;
      font-weight: 500;
      transition: all .4s;
    }

    .MuiAccordionDetails-root {
      display: flex;
      flex-direction: column;
      gap: 4px;
      padding: 16px 8px;
      background-color: $navbar-bg;
    }

    .menu-container {
      padding: 12px;
      border-radius: 8px;

      &:hover {
        background-color: $navbar-accordion-hover;

        .menu-title {
          color: $white;
        }
      }
    }

  }

  // nest accordion
  .navbar-accordion-child {
    &.MuiAccordion-root {
      background-color: $navbar-bg;
      box-shadow: none;

      &::before {
        display: none;
      }

      .MuiAccordionSummary-content {
        color: $white !important;
      }

      &.Mui-expanded {
        margin-top: 0;

        .MuiAccordionSummary-root {
          background-color: $navbar-accordion-hover;
          border-radius: 8px 8px 0px 0px;
        }
      }

      .MuiAccordionSummary-expandIconWrapper {
        transform: rotate(180deg);

        &.Mui-expanded {
          transform: rotate(0deg);
        }
      }

      .MuiAccordionDetails-root {
        padding: 0 0px 12px 12px;
        background-color: $navbar-accordion-hover;
        border-radius: 0px 0px 8px 8px;

        .menu-list-wrapper {
          position: relative;
          padding-left: 16px;
          display: flex;
          flex-direction: column;
          gap: 4px;

          &::before {
            content: "";
            width: .5px;
            background-color: $white;
            position: absolute;
            left: 0;
            top: 0;
            height: calc(100% - 12px)
          }
        }

        .list-container {
          display: flex;
          align-items: center;
          gap: 8px;
          padding: 4px 6px;
          margin-right: 24px;
          border-radius: 4px;
          cursor: pointer;
          transition: all .4s;
          height: 24px;

          &:hover,
          &.active {
            background-color: $navbar-acc-menu;
          }
        }

        .tree-line {
          position: relative;

          &::before {
            content: "";
            background-color: $white;
            position: absolute;
            width: 8px;
            height: .5px;
            left: -15px;
            top: 11px;

          }
        }

        .menu-list-container {
          padding-right: 24px;

          .list-container {
            margin-right: 0;
          }
        }

        .nest-menu-list {
          // color: re;
          font-size: 13px;
          font-weight: 500;
          color: $white;

        }
      }

      .MuiAccordionSummary-root {
        background-color: $navbar-bg;
        border-radius: 8px;
        padding: 12px;
        min-height: auto !important;

        &:hover {
          background-color: $navbar-accordion-hover;
        }
      }
    }
  }
}

.sidenav-menu {
  .MuiPaper-root {
    background-color: $white;
    border-radius: 8px;
    border: 1px solid $menu-border;
    box-shadow: 0px 4px 20px 0px #00000021;
    min-width: 200px;
  }

  .MuiList-root {
    padding: 8px !important;
  }

  .MuiButtonBase-root {
    display: flex;
    align-items: center;
    max-height: 32px;
    padding: 8px 12px;
    font-weight: 500;
    font-size: 13px;
    color: $menu-text;
    transition: all .4s;
    border-radius: 4px;
    font-family: $base-font-family;

    &:hover {
      background-color: $menu-hover;
    }
  }
}
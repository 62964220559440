@import '/src/scss/helpers/index.scss';

.content-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 40px 20px;
}

.child-wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
  min-width: 420px;
  max-width: 420px;

}

.child-header {
  display: flex;
  align-items: center;
  gap: 12px;
}

.child-title {
  font-weight: 500;
  font-size: 13px;
  color: $menu-text;
  text-transform: capitalize;
}

.input-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.number-wrapper {
  display: flex;
  gap: 16px;
}

.chip-wrapper {
  display: flex;
  align-items: center;
  gap: 6px;
  flex-wrap: wrap;
}

.input-add-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  background-color: $navbar-bg;
  border-radius: 50px;
  cursor: pointer;
}

.add-btn {
  margin-top: 8px !important;
  @include iconSize(24px, 24px);
  min-width: 24px !important;
}